import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Topbar from '../../components/Topbar/Topbar';
import { DashboardConfType, ICONS_PATH } from './types';
import { dashboardsApolloClientFactory } from '../../graphql';
import { useDashboardsQuery } from '../../graphql/hooks';
import { GetUserDashboards, GetDashboard } from './graphql';
import Sidebar, { ItemType } from '../../components/Sidebar';
import { useWindowDimensions } from '../../hooks/window';
import Spinner from '../../components/Spinner';

export default function DashboardsViewer() {
  const { t } = useTranslation('', { keyPrefix: 'dashboards' });
  const [dashboards, setDashboards] = useState<ItemType[]>([] as ItemType[]);
  const [selectedDashboard, setSelectedDashboard] = useState<ItemType>({} as ItemType);
  const [activeDashboard, setActiveDashboard] = useState('');
  const [isLoadingSideBar, setIsLoadingSideBar] = useState(true);
  const [isLoadingDashboard, setIsLoadingDashboard] = useState(true);
  const { height } = useWindowDimensions();

  useEffect(() => {
    if (dashboards.length > 0) {
      const selectedDashboard = dashboards.filter((dashboard: ItemType) => dashboard.isSelected)[0];
      if (selectedDashboard) {
        setIsLoadingDashboard(true);
        setSelectedDashboard(selectedDashboard);

        getDashboard(selectedDashboard.key);
      }
    }
  }, [JSON.stringify(dashboards)]);

  useDashboardsQuery(GetUserDashboards, {
    onCompleted: (data) => {
      if (data?.GetUserDashboards?.dashboards) {
        const dashboards = data.GetUserDashboards.dashboards.map((dashboard: DashboardConfType) => ({
          ...dashboard,
          id: dashboard.dashboard_id,
          name: dashboard.title ?? t(dashboard.name),
          key: dashboard.name,
          iconName: dashboard.icon_name,
          iconSrc: `${ICONS_PATH}gray_${dashboard.icon_name}.svg`,
        }));

        if (dashboards.length > 0) {
          dashboards[0].isSelected = true;
          dashboards[0].iconSrc = `${ICONS_PATH}purple_${dashboards[0].iconName}.svg`;
          setDashboards(dashboards);

          setIsLoadingSideBar(false);
        }
      }
    },
  });

  function getDashboard(dashboardName: string) {
    setIsLoadingDashboard(true);

    dashboardsApolloClientFactory()
      .query({
        query: GetDashboard,
        variables: {
          dashboard_name: dashboardName,
        },
      })
      .then((response) => {
        if (response.data.GetDashboard.dashboard) {
          setActiveDashboard(response.data.GetDashboard.dashboard.dashboard);

          setIsLoadingDashboard(false);
        }
      })
      .catch((reason) => {
        console.error(reason);
      });
  }

  const CentralizedSpinner = () => (
    <div className={`flex justify-center items-center w-full h-full min-h-100`}>
      <Spinner />
    </div>
  );

  return (
    <div className='flex flex-row max-h-screen max-w-screen h-screen bg-zinc-50'>
      {isLoadingSideBar ? (
        <CentralizedSpinner />
      ) : (
        <>
          <Sidebar items={dashboards} setItems={setDashboards} iconsPath={ICONS_PATH} />
          <div id='dashboard-container' className='flex justify-evenly w-full'>
            <div className={`flex flex-col w-full`}>
              <Topbar header={selectedDashboard.name} />
              <hr className='mx-12 border-purple-faded' />
              {isLoadingDashboard ? (
                <CentralizedSpinner />
              ) : (
                <iframe id='dashboard-iframe' className='w-full' srcDoc={activeDashboard} height={height} scrolling='no' />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
